import { createTheme as createMuiTheme } from '@mui/material';

export const theme = {
  palette: {
    primary: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#1565c0',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
  },
};

// Create Material-UI theme
export const muiTheme = createMuiTheme(theme);

// Create styled-components theme
export const styledTheme = {
  ...theme,
  // Add any styled-components specific theme properties here
  colors: theme.palette,
  spacing: (factor) => `${8 * factor}px`,
}; 