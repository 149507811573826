import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import BusinessIcon from '@mui/icons-material/Business';
import TimelineIcon from '@mui/icons-material/Timeline';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

const StyledCard = styled(Card)`
  height: 100%;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-8px);
  }
`;

const About = () => {
  const theme = useTheme();

  const milestones = [
    {
      year: '2011',
      title: 'Company Founded',
      description: 'Bizscape was established with a vision to revolutionize business solutions.',
    },
    {
      year: '2023',
      title: 'BizXHub Launch',
      description: 'Successfully launched our flagship product BizXHub to the market.',
    }
  ];

  const values = [
    {
      icon: <BusinessIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Business Excellence',
      description: 'We strive for excellence in everything we do, delivering the best solutions to our clients.',
    },
    {
      icon: <TimelineIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Innovation',
      description: 'Constantly pushing boundaries to create cutting-edge solutions for modern businesses.',
    },
    {
      icon: <EmojiObjectsIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Customer Success',
      description: 'Your success is our priority. We work closely with clients to ensure their business thrives.',
    },
  ];

  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h2" component="h1" textAlign="center" gutterBottom>
            About Bizscape
          </Typography>
          <Typography variant="h5" textAlign="center" color="text.secondary" paragraph>
            Empowering businesses with innovative solutions since 2011
          </Typography>
        </motion.div>

        <Box sx={{ my: 8 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Our Story
          </Typography>
          <Typography paragraph>
            Founded in 2011, Bizscape emerged from a simple observation: businesses needed a more efficient,
            integrated solution to manage their operations. Today, we're proud to serve our clients
            with our flagship product, BizXHub.
          </Typography>
        </Box>

        <Box sx={{ my: 8 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Our Values
          </Typography>
          <Grid container spacing={4}>
            {values.map((value, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  <StyledCard>
                    <CardContent sx={{ textAlign: 'center' }}>
                      {value.icon}
                      <Typography variant="h5" component="h3" gutterBottom>
                        {value.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {value.description}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ my: 8 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Our Journey
          </Typography>
          <Grid container spacing={4}>
            {milestones.map((milestone, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h4" color="primary" gutterBottom>
                        {milestone.year}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {milestone.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {milestone.description}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default About; 