import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StorageIcon from '@mui/icons-material/Storage';
import SecurityIcon from '@mui/icons-material/Security';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CloudIcon from '@mui/icons-material/Cloud';
import SupportIcon from '@mui/icons-material/Support';
import IntegrationIcon from '@mui/icons-material/IntegrationInstructions';

const ServiceCard = styled(Card)`
  height: 100%;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-8px);
  }
`;

const Services = () => {
  const theme = useTheme();

  const services = [
    // {
    //   icon: <StorageIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
    //   title: 'Data Management',
    //   description: 'Efficiently manage and organize your business data with our advanced storage solutions.',
    //   features: [
    //     'Automated data backup',
    //     'Real-time synchronization',
    //     'Custom data organization',
    //     'Version control',
    //   ],
    // },
    // {
    //   icon: <SecurityIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
    //   title: 'Security Solutions',
    //   description: 'Protect your business with enterprise-grade security features and protocols.',
    //   features: [
    //     'End-to-end encryption',
    //     'Multi-factor authentication',
    //     'Role-based access control',
    //     'Security audit logs',
    //   ],
    // },
    // {
    //   icon: <AnalyticsIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
    //   title: 'Business Analytics',
    //   description: 'Make data-driven decisions with our comprehensive analytics tools.',
    //   features: [
    //     'Real-time dashboards',
    //     'Custom reports',
    //     'Predictive analytics',
    //     'Performance metrics',
    //   ],
    // },
    {
      icon: <IntegrationIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Integration Services',
      description: 'Seamlessly integrate with multiple platforms.',
      features: [
        'EDI integration',
        'API integration',
        'Hub and Spoke integration',
      ],
    },
    {
      icon: <CloudIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Cloud Services',
      description: 'Seamless, secure and scalable Azure cloud solution tailored for your business',
      features: [
        'Azure App Services',
        'Azure Storage',
      ],
    },
   
    {
      icon: <SupportIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Support',
      description: 'Connect with our dedidated team for assistance.',
      features: [
        'Email support',
      ],
    },
  ];

  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h2" component="h1" textAlign="center" gutterBottom>
            Our Services
          </Typography>
          <Typography variant="h5" textAlign="center" color="text.secondary" paragraph>
            Comprehensive solutions for modern businesses
          </Typography>
        </motion.div>

        <Box sx={{ my: 8 }}>
          <Typography variant="h4" component="h2" gutterBottom>
            BizXHub Features
          </Typography>
          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid item xs={12} md={6} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <ServiceCard>
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        {service.icon}
                        <Typography variant="h5" component="h3" sx={{ ml: 2 }}>
                          {service.title}
                        </Typography>
                      </Box>
                      <Typography color="text.secondary" paragraph>
                        {service.description}
                      </Typography>
                      <List>
                        {service.features.map((feature, featureIndex) => (
                          <ListItem key={featureIndex}>
                            <ListItemIcon>
                              <CheckCircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={feature} />
                          </ListItem>
                        ))}
                      </List>
                      {/* <Box sx={{ mt: 2, textAlign: 'right' }}>
                        <Button variant="contained" color="primary">
                          Learn More
                        </Button>
                      </Box> */}
                    </CardContent>
                  </ServiceCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ my: 8, textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            Ready to Transform Your Business?
          </Typography>
          <Typography variant="h6" color="text.secondary" paragraph>
            Join other businesses already using BizXHub
          </Typography>
          <Button variant="contained" color="primary" size="large">
            Get Started Today
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Services; 