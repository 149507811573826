import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import SpeedIcon from '@mui/icons-material/Speed';
import SecurityIcon from '@mui/icons-material/Security';
import AnalyticsIcon from '@mui/icons-material/Analytics';

const HeroSection = styled(Box)`
  background: linear-gradient(135deg, #1976d2 0%, #42a5f5 100%);
  color: white;
  padding: 100px 0;
  text-align: center;
`;

const ProductLogo = styled.img`
  height: 120px;
  margin-bottom: 24px;
`;

const FeatureCard = styled(Card)`
  height: 100%;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-8px);
  }
`;

const Home = () => {
  const theme = useTheme();

  const features = [
    {
      icon: <SpeedIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />,
      title: 'Lightning Fast',
      description: 'Experience unprecedented speed with our optimized solutions.',
    }
  ];

  return (
    <Box>
      <HeroSection>
        <Container>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <ProductLogo src="/BizXHub/bxhub_logo.png" alt="BizXHub Logo" />
            <Typography variant="h2" component="h1" gutterBottom>
              Transform Your Business with BizXHub
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
              A hub that brings together retailers, vendors, and 3PL warehouses to enable a seamless fulfillment workflow
            </Typography>
            <Button
              component={RouterLink}
              to="/services"
              variant="contained"
              color="secondary"
              size="large"
              sx={{ mr: 2 }}
            >
              Explore Services
            </Button>
            <Button
              component={RouterLink}
              to="/about"
              variant="outlined"
              color="secondary"
              size="large"
            >
              Learn More
            </Button>
          </motion.div>
        </Container>
      </HeroSection>

      <Container sx={{ py: 8 }}>
        <Typography variant="h3" component="h2" textAlign="center" gutterBottom>
          Why Choose BizXHub?
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <FeatureCard>
                  <CardContent sx={{ textAlign: 'center' }}>
                    {feature.icon}
                    <Typography variant="h5" component="h3" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {feature.description}
                    </Typography>
                  </CardContent>
                </FeatureCard>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Home; 
