import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const FooterWrapper = styled(Box)`
  background-color: ${props => props.theme.colors.primary.main};
  color: white;
  padding: ${props => props.theme.spacing(6)} 0;
`;

const Footer = () => {
  const socialLinks = [
    { icon: <FacebookIcon />, url: 'https://facebook.com/bizscape' },
    { icon: <TwitterIcon />, url: 'https://twitter.com/bizscape' },
    { icon: <LinkedInIcon />, url: 'https://linkedin.com/company/bizscape' },
    { icon: <InstagramIcon />, url: 'https://instagram.com/bizscape' },
  ];

  return (
    <FooterWrapper>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              About Bizscape
            </Typography>
            <Typography variant="body2" paragraph>
              Empowering businesses with innovative solutions through our flagship product BizXHub.
              We're committed to helping businesses thrive in the digital age.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', p: 0 }}>
              <Box component="li" sx={{ mb: 1 }}>
                <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
                  Home
                </Link>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Link to="/about" style={{ color: 'inherit', textDecoration: 'none' }}>
                  About
                </Link>
              </Box>
              <Box component="li" sx={{ mb: 1 }}>
                <Link to="/services" style={{ color: 'inherit', textDecoration: 'none' }}>
                  Services
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" paragraph>
              Belle Mead, NJ 08502
            </Typography>
            <Typography variant="body2" paragraph>
              Email: info@bizscape.us
            </Typography>
            <Box sx={{ mt: 2 }}>
              {socialLinks.map((social, index) => (
                <IconButton
                  key={index}
                  color="inherit"
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ mr: 1 }}
                >
                  {social.icon}
                </IconButton>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 4, pt: 2, borderTop: '1px solid rgba(255, 255, 255, 0.1)' }}>
          <Typography variant="body2" textAlign="center">
            © {new Date().getFullYear()} Bizscape. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

export default Footer; 
